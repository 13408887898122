
export default {
  name: 'Grid',
  props: {
    content: {
      type: Array,
      required: true
    },
    backgroundColor: {
      type: String,
      required: false,
      default: ''
    },
    backgroundColorHex: {
      type: String,
      default: null
    },
    mobileHide: {
      type: Boolean,
      required: false,
      default: false
    },
    mobileColumns: {
      type: String,
      required: false,
      default: '2'
    },
    mobilePadding: {
      type: String,
      required: false,
      default: 'mp--none'
    },
    mobileGutter: {
      type: String,
      required: false,
      default: 'none'
    },
    desktopHide: {
      type: Boolean,
      required: false,
      default: false
    },
    desktopColumns: {
      type: String,
      required: false,
      default: '2'
    },
    desktopPadding: {
      type: String,
      required: false,
      default: 'dp--none'
    },
    desktopGutter: {
      type: String,
      required: false,
      default: 'none'
    },
    pageMeta: { // Meta data about the page component this is a part of
      type: Object,
      default: () => {}
    },
  },
  computed: {
    /**
     * Depending on the number of columns, we pass the 'mobileDisplayWidthOfViewportWidth' and 'desktopDisplayWidthOfViewportWidth'
     * props into the child component where we can apply them on images to get optimized image sizes
     */
    mobileDisplayWidthOfViewportWidth () {
      return 100 / parseInt(this.mobileColumns)
    },
    desktopDisplayWidthOfViewportWidth () {
      return 100 / parseInt(this.desktopColumns)
    },
    backgroundColorValue () {
      return this.backgroundColorHex || this.backgroundColor
    }
  }
}
